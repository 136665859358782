/* eslint-disable no-param-reassign */
import useGeolocation from '@website/hooks/geoLocation/useGeolocation';
import { Price } from '@website/types/graphqlOperations';
import { useContext, useMemo } from 'react';
import currencies from '../../dataObjects/currencies';
import { Store } from '../../Store';

/**
 *
 * Convert price from zeroDecimals to decimals
 *
 */
export function priceZdToDecimals({
  currencyIso,
  amount,
  roundDollar,
  showDecimalsMin2,
  forFacebook,
  roundAmountOff = false,
}: {
  currencyIso: string;
  amount: number;
  roundDollar?: boolean;
  showDecimalsMin2?: boolean;
  forFacebook?: boolean;
  roundAmountOff?: boolean;
}): string {
  // Get currency ZD multiplier
  const currency = currencies[currencyIso];
  const decimals = currency.decimal_digits;
  const multiplier = 10 ** decimals;

  // Convert ZD to value
  let value = Math.ceil(amount);
  value /= multiplier;

  // Round dollar
  if (roundDollar) {
    value = Math.ceil(value);
  } else if (roundAmountOff) {
    value = Math.round(value);
  }

  // Setup Intl Number settings
  const numberSettings: Intl.NumberFormatOptions = {};
  if (showDecimalsMin2) {
    numberSettings.minimumFractionDigits = 2;
  } else if (value % 1 === 0) {
    // i.e. if nothing after the decimal place
    numberSettings.minimumFractionDigits = 0;
  }

  let res = new Intl.NumberFormat(
    forFacebook ? 'en-US' : undefined,
    numberSettings,
  ).format(value);
  if (forFacebook) {
    res = res.replace(',', '');
  }

  return res;
}

type Props = {
  currencyIso: string;
  amount: number;
  short?: boolean;
  convert?: boolean;
  roundDollar?: boolean;
  roundAmountOff?: boolean;
  hideCurrency?: boolean;
};

export default function PriceFormat({
  amount,
  currencyIso,
  short = false,
  convert = false,
  roundDollar = false,
  roundAmountOff = false,
  hideCurrency = false,
}: Props) {
  const { state } = useContext(Store);
  const geoLocation = useGeolocation();

  // Convert
  const convertedPrice = useMemo<Price>(() => {
    // Get the currency rates
    const priceXCCY = geoLocation?.xccyRates?.find(
      (rate) => rate.currency === currencyIso,
    );
    const preferredXCCY = geoLocation?.xccyRates?.find(
      (rate) => rate.currency === state.preferred_currency,
    );

    if (priceXCCY && preferredXCCY && convert) {
      return {
        amountZD: (amount / priceXCCY.rate) * preferredXCCY.rate,
        currency: state.preferred_currency,
      };
    }

    return {
      amountZD: amount,
      currency: currencyIso,
    };
  }, [amount, currencyIso, state, geoLocation?.xccyRates, convert]);

  // Adjust initial value to decimals
  const valueDecimals = priceZdToDecimals({
    currencyIso: convertedPrice.currency,
    amount: Math.abs(convertedPrice.amountZD),
    roundDollar,
    roundAmountOff,
  });

  const iso = short ? '' : ` ${convertedPrice.currency}`;
  const currency = currencies[convertedPrice.currency];
  const { symbol } = currency;

  if (amount < 0) {
    return (
      <span className="price-formatted">{`(${symbol}${valueDecimals}${hideCurrency ? '' : iso})`}</span>
    );
  }

  return (
    <span className="price-formatted">{`${symbol}${valueDecimals}${hideCurrency ? '' : iso}`}</span>
  );
}
