import { useMutation } from '@apollo/client';
import AuthenticationModal from '@website/components/auth/AuthenticationModal';
import { Store } from '@website/Store';
import { AddUserToWishlistDocument } from '@website/types/graphqlOperations';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FaRegHeart, FaSpinner } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import styles from './wishlist.module.scss';

type JoinWishListButtonProps = {
  tripId: string;
  onSuccess?: () => void;
  isTripsPage?: boolean;
};

export default function JoinWishListButton({
  tripId,
  onSuccess = () => {},
  isTripsPage = false,
}: JoinWishListButtonProps) {
  const { state } = useContext(Store);
  const [isJoinButtonClicked, setIsJoinButtonClicked] = useState(false);

  const isAuthenticated = !!state.user.id;

  const [showAuthModal, setShowAuthModal] = useState(false);

  const [addToWishlist, { loading }] = useMutation(AddUserToWishlistDocument, {
    variables: {
      input: {
        tripID: tripId,
        userID: state.user.id,
      },
    },
    onCompleted: () => {
      onSuccess();
      setIsJoinButtonClicked(false);
    },
    update(cache, { data }) {
      const userCacheID = cache.identify({
        __typename: 'User',
        id: data.addUserToWishlist.user.id,
      });
      const waitListCacheID = cache.identify({
        __typename: 'Wishlist',
        id: data.addUserToWishlist.id,
      });

      // add the waitlist to the identified group waitlist cache
      cache.modify({
        id: userCacheID,
        fields: {
          wishlist(cachedWishlistItems) {
            return {
              items: [...cachedWishlistItems.items, { __ref: waitListCacheID }],
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    if (isJoinButtonClicked && isAuthenticated) {
      addToWishlist({
        variables: {
          input: {
            tripID: tripId,
            userID: state.user.id,
          },
        },
      });
    }
  }, [addToWishlist, isAuthenticated, isJoinButtonClicked, state, tripId]);

  return (
    <>
      <button
        type="button"
        disabled={loading}
        className={clsx(
          styles.wishlistButton,
          styles.iconUnselected,
          isTripsPage && styles.wishlistButtonHidden,
        )}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (isAuthenticated) {
            await addToWishlist();
          } else {
            setShowAuthModal(true);
          }
        }}
        data-tooltip-id="join-wishlist-tooltip"
        data-tooltip-content="Add to wishlist"
        data-tooltip-class-name="text-xs"
      >
        {loading ? <FaSpinner /> : <FaRegHeart />}
      </button>
      <Tooltip id="join-wishlist-tooltip" />
      {showAuthModal &&
        createPortal(
          <AuthenticationModal
            showModal={showAuthModal}
            onSuccess={async () => {
              setShowAuthModal(false);
              setIsJoinButtonClicked(true);
            }}
            onHide={() => setShowAuthModal(false)}
          />,
          document.body,
        )}
    </>
  );
}
