import { TripsPageQuery } from '@website/types/graphqlOperations';
import { useMemo } from 'react';

export default function useTripsDiscountRate(
  trip: Pick<TripsPageQuery['tripByURL'], 'minimumPrice'>,
) {
  const discountRate = useMemo(() => {
    if (trip.minimumPrice && trip.minimumPrice.priceBeforeDiscount) {
      return (
        // we calculate the discount rate
        Math.round(
          (1 -
            trip.minimumPrice.amountZD /
              trip.minimumPrice.priceBeforeDiscount.amountZD) *
            100,
        )
      );
    }
    return undefined;
  }, [trip?.minimumPrice]);

  return discountRate;
}
