/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useWindowSize } from '@website/hooks/useWindowsSize';
import { Media } from '@website/types/graphqlOperations';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaExpandAlt, FaTrash } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Image, composeImageURL } from '../media/utils/imgix';

/**
 * Create the sizes attribute for the image
 *
 * This is created based on the number of columns. For example if all devices
 * widths have 3 columns, then the sizes attribute will be 33vw.
 *
 * Bootstrap sizes from here:
 * https://getbootstrap.com/docs/5.0/layout/containers/
 *
 * @example
 * createImageSizes({ xs: 2, lg: 3 })
 * // => "(min-width: 992px) 33.33vw, 50vw"
 */
export function createImageSizes({
  xs = 12,
  sm = xs,
  md = sm,
  lg = md,
  xl = lg,
  xxl = xl,
  fluid = false,
}: {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  fluid?: boolean;
}): string {
  // Bootstrap column sizes
  const breakpoints = [
    { name: 'xxl', minWidth: 1400, container: 1320, columnSize: xxl },
    { name: 'xl', minWidth: 1200, container: 1140, columnSize: xl },
    { name: 'lg', minWidth: 992, container: 960, columnSize: lg },
    { name: 'md', minWidth: 768, container: 720, columnSize: md },
    { name: 'sm', minWidth: 576, container: 540, columnSize: sm },
    { name: 'xs', minWidth: 0, container: undefined, columnSize: xs },
  ];

  // Sizes to allow for container/col padding
  const containerMargins = 24;
  const colPadding = 2;

  // Create the sizes attribute
  const sizes = breakpoints
    .map(({ minWidth, container, columnSize }) => {
      const screenMinWidth: string =
        minWidth === 0 ? '' : `(min-width: ${minWidth}px) `;

      let imageWidth: string;

      if (fluid) {
        imageWidth = `${Number(((100 * columnSize) / 12).toFixed(2))}vw`;
      } else {
        const fixedContainerWidth = Math.ceil(
          ((container - containerMargins) * columnSize) / 12 - colPadding,
        );

        const fluidContainerWidth = Number(
          ((100 * columnSize) / 12).toFixed(2),
        );

        imageWidth = container
          ? `${fixedContainerWidth}px`
          : `${fluidContainerWidth}vw`;
      }

      return `${screenMinWidth}${imageWidth}`;
    })
    .join(', ');

  return sizes;
}

/**
 * Create classes for a column to show only on a single row
 *
 * For example, if we have xs=3 columns, then we want to add the class
 * `display-xs-none` to the 4th column.
 */
export function generateSingleRowClasses({
  xs = 12,
  sm = xs,
  md = sm,
  lg = md,
  xl = lg,
  xxl = xl,
  currentIndex,
}: {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  currentIndex: number;
}): string {
  // Breakpoints
  const breakpoints = [
    { name: 'xs', columnSize: xs },
    { name: 'sm', columnSize: sm },
    { name: 'md', columnSize: md },
    { name: 'lg', columnSize: lg },
    { name: 'xl', columnSize: xl },
    { name: 'xxl', columnSize: xxl },
  ];

  const classes = breakpoints.map(({ name, columnSize }) => {
    const maxColumnsForSize = Math.floor(12 / columnSize);

    // Handle the xs case separately (as there are no d-xs-* classes)
    if (name === 'xs') {
      if (currentIndex >= maxColumnsForSize) {
        return 'd-none';
      }
    } else if (currentIndex < maxColumnsForSize) {
      return `d-${name}-block`;
    }

    return '';
  });

  return classes.filter((i) => i !== '').join(' ');
}

type Props = {
  images: Pick<Media, 'file' | 'id'>[];
  /** All images - if we're showing more in the modal than just the ones that
   * you can see without it */
  allImages?: Pick<Media, 'file' | 'id'>[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  singleRowOnly?: boolean;
  onDelete?: (id: string) => void;
};

/**
 * Gallery
 */
export default function Gallery({
  images,
  allImages = images,
  xs = 12,
  sm = xs,
  md = sm,
  lg = md,
  xl = lg,
  xxl = xl,
  singleRowOnly = false,
  onDelete,
}: Props) {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  // LightBox Imgix params
  const { width, height } = useWindowSize();
  const scale =
    typeof window !== 'undefined' ? window.devicePixelRatio || 1 : 1;
  const lightBoxImgixParams: Record<string, string | number | boolean> = {
    w: Math.round(width * scale),
    h: Math.round(height * scale),
    fit: 'max',
  };

  const onClick = (imageID: string) => {
    // find the index of the image in the allItineraryImages array
    const imageIndex = allImages.findIndex((image) => image.id === imageID);
    setActiveImage(imageIndex);
    setLightBoxOpen(!lightBoxOpen);
  };

  const customStyles = {
    overlay: {
      zIndex: '1050',
    },
    bodyOpen: {
      position: 'fixed',
    },
  };

  return (
    <>
      <Row className="g-1">
        {images.map((image, index) => (
          <Col
            key={image.id}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            xxl={xxl}
            className={`${
              singleRowOnly
                ? generateSingleRowClasses({
                    xs,
                    sm,
                    md,
                    lg,
                    xl,
                    xxl,
                    currentIndex: index,
                  })
                : ''
            }`}
          >
            <div
              role={'button'}
              onClick={() => onClick(image.id)}
              onKeyPress={() => onClick(image.id)}
              style={{
                position: 'relative',
                width: '100%',
                paddingTop: '66.666%',
                overflow: 'hidden',
              }}
            >
              <Image
                key={image.id}
                imgKey={image.file.key}
                alt={image.id}
                styles={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                imgixParams={{
                  ar: '3:2',
                }}
                sizes={createImageSizes({ xs, sm, md, lg, xl, xxl })}
              />

              {/* Delete button */}
              {onDelete && (
                <span
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    margin: 0,
                    padding: 10,
                    color: 'white',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDelete(image.id);
                    }}
                  >
                    <FaTrash size={18} />
                  </button>
                </span>
              )}

              {/* Expand icons */}
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  margin: 0,
                  padding: 10,
                  color: 'white',
                }}
              >
                <FaExpandAlt size={18} />
              </span>
            </div>
          </Col>
        ))}
      </Row>

      {lightBoxOpen && (
        <Lightbox
          mainSrc={composeImageURL(
            allImages[activeImage].file.key,
            lightBoxImgixParams,
          )}
          nextSrc={composeImageURL(
            allImages[(activeImage + 1) % allImages.length].file.key,
            lightBoxImgixParams,
          )}
          prevSrc={composeImageURL(
            allImages[(activeImage + allImages.length - 1) % allImages.length]
              .file.key,
            lightBoxImgixParams,
          )}
          onCloseRequest={() => setLightBoxOpen(false)}
          // imageCaption={data[activeImage].title}
          onMovePrevRequest={() =>
            setActiveImage(
              (activeImage + allImages.length - 1) % allImages.length,
            )
          }
          onMoveNextRequest={() =>
            setActiveImage((activeImage + 1) % allImages.length)
          }
          enableZoom={true}
          reactModalStyle={customStyles}
          imagePadding={0}
        />
      )}
    </>
  );
}
