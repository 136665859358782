import { useMutation } from '@apollo/client';
import { RemoveUserFromWishlistDocument } from '@website/types/graphqlOperations';
import clsx from 'clsx';
import { FaHeart, FaSpinner } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import styles from './wishlist.module.scss';

type LeaveWishListButtonProps = {
  tripId: string;
  userId: string;
  onSuccess?: () => void;
  isTripsPage?: boolean;
};

export default function LeaveWishListButton({
  tripId,
  userId,
  onSuccess = () => {},
  isTripsPage = false,
}: LeaveWishListButtonProps) {
  const [leaveWishList, { loading }] = useMutation(
    RemoveUserFromWishlistDocument,
    {
      variables: {
        input: {
          tripID: tripId,
          userID: userId,
        },
      },
      onCompleted: onSuccess,
      update(cache, { data }) {
        const wishlistId = cache.identify({
          __typename: 'Wishlist',
          id: data.removeUserFromWishlist.id,
        });

        cache.evict({
          id: wishlistId,
        });
      },
    },
  );

  return (
    <>
      <button
        className={clsx(
          styles.wishlistButton,
          styles.iconSelected,
          isTripsPage && styles.wishlistButtonHidden,
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          leaveWishList();
        }}
        disabled={loading}
        data-tooltip-id="leave-wishlist-tooltip"
        data-tooltip-class-name="text-xs"
        data-tooltip-content="Remove from wishlist"
      >
        {loading ? <FaSpinner /> : <FaHeart />}
      </button>
      <Tooltip id="leave-wishlist-tooltip" />
    </>
  );
}
